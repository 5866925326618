<script setup lang="ts">
import { Modal } from 'jenesius-vue-modal';

withDefaults(
  defineProps<{
    title?: string;
    message?: string;
    buttonText?: string;
  }>(),
  {
    title: '',
    message: '',
    buttonText: '확인',
  },
);

const emit = defineEmits<{
  // @ts-ignore
  (e: Modal.EVENT_PROMPT, action: string): void;
}>();

const route = useRoute();

watch(
  () => route,
  () => {
    handleVisible.value = false;
  },
  { deep: true },
);

const handleVisible = computed<boolean>({
  get: () => {
    return true;
  },
  set: (val) => {
    if (!val) {
      nextTick(() => {
        emit(Modal.EVENT_PROMPT, 'close');
      });
    }
  },
});
</script>

<template>
  <Dialog
    v-model:visible="handleVisible"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :modal="true"
    class="wb-alert">
    <p v-if="title" class="header">{{ title }}</p>
    <p class="message whitespace-pre-line" :class="{ 'with-title': !!title }">{{ message }}</p>

    <template #footer>
      <Button :label="buttonText" text class="w-full alert-button" autofocus @click="handleVisible = false" />
    </template>
  </Dialog>
</template>

<style lang="scss" scoped>
.header {
  font-family: Pretendard;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 8px;
}

.alert-button {
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}
.message {
  font-family: Pretendard;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #091227;

  &.with-title {
    font-size: 14px;
    color: #48546e;
  }
}
</style>
