import { promptModal } from 'jenesius-vue-modal';

import AppCustomAlert from '~/components/AppCustomAlert.vue';

export default ({
  title,
  message,
  helpText,
}: {
  title?: string;
  message?: string;
  helpText?: string | null;
}): Promise<'close'> => {
  return new Promise(async (resolve) => {
    // SSR 에서 모달을 여는 경우, 에러 발생
    if (!$isClient()) {
      return;
    }

    await nextTick(); // 컨테이너가 늦게생성되는 케이스가 있기때문에 nextTick을 걸어준다.
    resolve((await promptModal(AppCustomAlert, { title, message, helpText })) as 'close');
  });
};
